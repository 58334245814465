.match-container {
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 3rem 0;
}

.match-return-button {
  position: absolute;
  top: 15px;
  left: 10vw;
  font-size: 1.2rem;
}

.match-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  max-width: 300px;
}

.match-button {
  border-color: var(--primary-color);
}

.match-button:hover {
  border-color: var(--accent-color);
  color: var(--accent-color);
}

.match-row {
  display: flex;
  justify-content: space-between;
}

.match-column {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}

.width-full {
  width: 100%;
}

.gap-table {
  gap: .8rem;
}

.match-public-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-collapse: collapse;
}

.match-public-container > tbody > tr > td {
  padding: 0.2rem 0.4rem;
}

@media print {
  .match-container {
    overflow: initial;
  }

  .match-row-name {
    width: 350px;
  }

  td {
    font-size: 20px;
    color: black;
  }

  th {
    font-size: 24px;
    color: black;
  }
}
