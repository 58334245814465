@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --widget-bg: #252525;
  --primary-bg: #323232;
  --primary-color: #cdcbd0;
  --accent-color: #c70d3a;
  --second-color: #c70d3a;
  --alternative-color: #222222;
  --primary-palette: #363842;
}

* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--primary-color);
}

.container {
  overflow: hidden;
  position: relative;
  height: 100vh;
  display: grid;
  grid-template-rows: 4rem 1fr;
  background-color: var(--primary-bg);
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.gap-column {
  gap: 5px;
}

input, select {
  color: black;
  padding: 0.4em 0.8em;
  min-width: 230px;
}

input::placeholder, select::placeholder {
  color: var(--primary-bg);
  opacity: 0.4;
}

input:focus-visible, select:focus-visible {
  outline-color: var(--accent-color);
}

button {
  cursor: pointer;
  color: var(--primary-color);
  background-color: var(--widget-bg);
  border: 1px solid var(--alternative-color);
  border-radius: .5rem;
  width: max-content;
  margin: 0 auto;
  padding: .4rem .8rem;
}


@media print {
  .no-print {
    display: none;
  }
}
