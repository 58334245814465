@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
.navbar {
  display: flex;
  align-items: center;
  grid-gap: 0.2rem;
  gap: 0.2rem;
  padding: 0.5em 2em;
  background-color: var(--widget-bg);
}

.navbar-child {
  text-align: center;
  flex: 1 1;
}

.navbar-logo {
  height: 2rem;
}

.navbar-container-logo {
  display: flex;
  justify-items: center;
  align-items: center;
  grid-gap: .2rem;
  gap: .2rem;
}

.navbar-social {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  justify-content: flex-end;
  font-size: 1.5em;
  color: var(--primary-color);
}

.navbar-social--icon:hover {
  cursor: pointer;
  color: var(--second-color);
}

.container-login {
  padding: 1rem 4rem;
  border: 1px solid red;
  border-radius: 1rem;
  text-align: center;
}

.header-login {
  font-size: 1.4rem;
  text-transform: uppercase;
  padding: 0 0 .8em 0;
}

.input-login {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
}

.email-login {
}

.password-login {
}

.btn-login {
  font-weight: bold;
  margin-top: 1rem;
  padding: .4em .8em;
}

.new-team-container {
  padding: 10px 0.8rem 0 0.8rem;
  min-width: 200px;
  border: 1px solid var(--primary-color);
  border-radius: 0.5em;
  background-color: var(--widget-bg);
}

.new-team-title {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.new-team-form {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}

.new-team-success {
  display: none;
  opacity: 0;
  position: absolute;
  top: -80px;
  width: 100%;
  text-align: center;
  background-color: mediumseagreen;
  border: 1px solid mediumseagreen;
  border-radius: 1rem;
  padding: 0.4em 0.8em;
  transition: all 2s linear;
}

.new-team-success--active {
  display: block;
  opacity: 1;
  top:-50px;
}

.new-team-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-team-button {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-bg);
  font-weight: bold;
  padding: 0.4rem 0.8rem;
  margin-bottom: 10px;
}

.new-team-button:hover {
  border-color: var(--accent-color);
  color: var(--accent-color);
}

.new-match-container {
  padding: 10px 0.8rem 0 0.8rem;
  min-width: 200px;
  border: 1px solid var(--primary-color);
  border-radius: 0.5em;
  background-color: var(--widget-bg);
}

/*.new-match-container:hover {*/
/*  border-color: var(--accent-color);*/
/*}*/

/*.new-match-container:hover .new-match-title > div {*/
/*  color: var(--accent-color);*/
/*}*/

.new-match-title {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.new-match-form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 10px;
}

.new-match-form-container > * {
  min-height: 125px;
}

#match-date {
  margin-bottom: 14px;
}

.new-match-homeTeam {
  min-width: 200px;
}

.new-match-away {

}

.new-match-button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.new-match-button {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-bg);
  font-weight: bold;
  padding: 0.8em 0.2em;
}

.new-match-button:hover {
  border-color: var(--accent-color);
  color: var(--accent-color);
}

.match-card-container {
  cursor: pointer;
  text-decoration: none;
  min-height: 100px;
  min-width: 250px;
  max-width: 300px;
  border-radius: 0.5rem;
  background-color: var(--widget-bg);
  justify-content: flex-start;
  justify-items: center;
  align-items: center;
  grid-gap: 0.8em;
  gap: 0.8em;
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.match-card-container:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.match-card-date {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: right;
}

.match-card-teams {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 0.2em;
  gap: 0.2em;
}

.no-link {
  cursor: default;
}

.matches-container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;
  gap: 15px;
  width: 100vw;
  justify-content: center;
}

.dashboard-container {
  overflow: scroll;
  display: grid;
  grid-template-rows: auto 1fr;
  justify-content: center;
  justify-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100vw;
  padding: 1rem 0;
}

.dashboard-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0.5em 2em;
  width: 100vw;
  flex:1 1;
}

.dashboard-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100vw;
}

.match-container {
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 3rem 0;
}

.match-return-button {
  position: absolute;
  top: 15px;
  left: 10vw;
  font-size: 1.2rem;
}

.match-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 0.8em;
  gap: 0.8em;
  max-width: 300px;
}

.match-button {
  border-color: var(--primary-color);
}

.match-button:hover {
  border-color: var(--accent-color);
  color: var(--accent-color);
}

.match-row {
  display: flex;
  justify-content: space-between;
}

.match-column {
  display: flex;
  flex-direction: column;
  grid-gap: 0.2em;
  gap: 0.2em;
}

.width-full {
  width: 100%;
}

.gap-table {
  grid-gap: .8rem;
  gap: .8rem;
}

.match-public-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-collapse: collapse;
}

.match-public-container > tbody > tr > td {
  padding: 0.2rem 0.4rem;
}

@media print {
  .match-container {
    overflow: visible;
    overflow: initial;
  }

  .match-row-name {
    width: 350px;
  }

  td {
    font-size: 20px;
    color: black;
  }

  th {
    font-size: 24px;
    color: black;
  }
}

.error-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 1rem;
  padding: 0.4rem 0.8rem;
}

.match-access-container {
  align-items: center;
  align-content: center;
  justify-items: center;
  padding: 2rem 0;
}

.match-access-wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
}

.match-access-title {
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
}

.match-access-count {
  text-transform: uppercase;
  font-weight: bold;
  color: var(--second-color);
  font-size: 0.95rem;
  text-align: center;
}

.match-access-form {
  display: flex;
  flex-direction: column;
  justify-items: center;
  grid-gap: .4em;
  gap: .4em;
}

.match-access-form > input {
  padding: 0.4em 0.8em;
  min-width: 230px;
}


.match-access-button {
  cursor: pointer;
  color: var(--primary-color);
  background-color: var(--widget-bg);
  border: 1px solid var(--alternative-color);
  border-radius: .5rem;
  width: -webkit-max-content;
  width: max-content;
  margin: 0 auto;
  padding: .4rem .8rem;
}

.success-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  padding: 0.4rem 0.8rem;
}

:root {
  --widget-bg: #252525;
  --primary-bg: #323232;
  --primary-color: #cdcbd0;
  --accent-color: #c70d3a;
  --second-color: #c70d3a;
  --alternative-color: #222222;
  --primary-palette: #363842;
}

* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #cdcbd0;
  color: var(--primary-color);
}

.container {
  overflow: hidden;
  position: relative;
  height: 100vh;
  display: grid;
  grid-template-rows: 4rem 1fr;
  background-color: #323232;
  background-color: var(--primary-bg);
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.gap-column {
  grid-gap: 5px;
  gap: 5px;
}

input, select {
  color: black;
  padding: 0.4em 0.8em;
  min-width: 230px;
}

input:-ms-input-placeholder, select:-ms-input-placeholder {
  color: #323232;
  color: var(--primary-bg);
  opacity: 0.4;
}

input::placeholder, select::placeholder {
  color: #323232;
  color: var(--primary-bg);
  opacity: 0.4;
}

input:focus-visible, select:focus-visible {
  outline-color: #c70d3a;
  outline-color: var(--accent-color);
}

button {
  cursor: pointer;
  color: #cdcbd0;
  color: var(--primary-color);
  background-color: #252525;
  background-color: var(--widget-bg);
  border: 1px solid #222222;
  border: 1px solid var(--alternative-color);
  border-radius: .5rem;
  width: -webkit-max-content;
  width: max-content;
  margin: 0 auto;
  padding: .4rem .8rem;
}


@media print {
  .no-print {
    display: none;
  }
}

