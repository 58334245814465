.new-match-container {
  padding: 10px 0.8rem 0 0.8rem;
  min-width: 200px;
  border: 1px solid var(--primary-color);
  border-radius: 0.5em;
  background-color: var(--widget-bg);
}

/*.new-match-container:hover {*/
/*  border-color: var(--accent-color);*/
/*}*/

/*.new-match-container:hover .new-match-title > div {*/
/*  color: var(--accent-color);*/
/*}*/

.new-match-title {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.new-match-form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-bottom: 10px;
}

.new-match-form-container > * {
  min-height: 125px;
}

#match-date {
  margin-bottom: 14px;
}

.new-match-homeTeam {
  min-width: 200px;
}

.new-match-away {

}

.new-match-button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.new-match-button {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-bg);
  font-weight: bold;
  padding: 0.8em 0.2em;
}

.new-match-button:hover {
  border-color: var(--accent-color);
  color: var(--accent-color);
}
