.dashboard-container {
  overflow: scroll;
  display: grid;
  grid-template-rows: auto 1fr;
  justify-content: center;
  justify-items: center;
  gap: 1rem;
  width: 100vw;
  padding: 1rem 0;
}

.dashboard-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  padding: 0.5em 2em;
  width: 100vw;
  flex:1;
}

.dashboard-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100vw;
}
