.new-team-container {
  padding: 10px 0.8rem 0 0.8rem;
  min-width: 200px;
  border: 1px solid var(--primary-color);
  border-radius: 0.5em;
  background-color: var(--widget-bg);
}

.new-team-title {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.new-team-form {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.new-team-success {
  display: none;
  opacity: 0;
  position: absolute;
  top: -80px;
  width: 100%;
  text-align: center;
  background-color: mediumseagreen;
  border: 1px solid mediumseagreen;
  border-radius: 1rem;
  padding: 0.4em 0.8em;
  transition: all 2s linear;
}

.new-team-success--active {
  display: block;
  opacity: 1;
  top:-50px;
}

.new-team-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-team-button {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-bg);
  font-weight: bold;
  padding: 0.4rem 0.8rem;
  margin-bottom: 10px;
}

.new-team-button:hover {
  border-color: var(--accent-color);
  color: var(--accent-color);
}
